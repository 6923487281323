import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FuncionalidadeModule } from './components/funcionalidade/funcionalidade.module';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmkRectangleButtonComponent } from './components/smk-rectangle-button/smk-rectangle-button.component';
import { HeaderInterceptor } from './providers/interceptors/header-interceptor';
import { LocalStorageService } from './providers/local-storage.service';
import { InputFileComponent } from './components/input-file/input-file.component';
import { InputBuscaComponent } from './components/input-busca/input-busca.component';
import { FormsModule } from '@angular/forms';
import { AutenticacaoService } from './providers/autenticacao.service';
import { CustomColumnDirective } from './components/data-list/directives/custom-column.directive';
import { SmkConfirmacaoComponent } from './components/modals/smk-confirmacao/smk-confirmacao.component';
import { ConfirmacaoComponent } from './components/modals/confirmacao/confirmacao.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SmkModalComponent } from './components/smk-modal/smk-modal.component';
import { SelectMultipleComponent } from './components/select-multiple/select-multiple.component';
import { SelectMultipleOptionPanelComponent } from './components/select-multiple-option-panel/select-multiple-option-panel.component';
import { NgxMaskDirective } from 'ngx-mask';

@NgModule({
  declarations: [
    SmkRectangleButtonComponent,
    InputFileComponent,
    InputBuscaComponent,
    CustomColumnDirective,
    ConfirmacaoComponent,
    SmkConfirmacaoComponent,
    SmkModalComponent,
    SelectMultipleComponent,
    SelectMultipleOptionPanelComponent,
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    FuncionalidadeModule,
    FormsModule,
    NgSelectModule,
    NgxMaskDirective,
  ],
  exports: [
    FuncionalidadeModule, //TODO Revisar
    SmkRectangleButtonComponent,
    InputFileComponent,
    InputBuscaComponent,
    CustomColumnDirective,
    NgSelectModule,
    SmkModalComponent,
    SelectMultipleComponent,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HeaderInterceptor,
          multi: true,
        },
        LocalStorageService,
        AutenticacaoService,
      ],
    };
  }
}
